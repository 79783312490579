import { Trans, t } from '@lingui/macro'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import SearchIcon from '@mui/icons-material/Search'
import SearchOffIcon from '@mui/icons-material/SearchOff'
import { Box, Button, CircularProgress, InputAdornment, SxProps, TextField, TextFieldProps, Typography, useTheme } from '@mui/material'
import { TrackingActionTypes } from '@om1/platform-tracking'
import { ActionWithPayload } from '@om1/platform-utils'
import {
    ChangeEventHandler,
    Dispatch,
    FunctionComponent,
    MouseEventHandler,
    PropsWithChildren,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import { DebounceInput } from 'react-debounce-input'
import { DeleteCohortDialog } from '../../components/list/DeleteCohortDialog'
import { CohortListItem, CohortLoading, ReportAnalyticsType, cohortCommonActions, cohortDeleteActions, cohortListActions } from '../../state'
import { DashboardCohortOwner } from './CohortDashboardPageComponent'
import { DashboardCohortCard } from './DashboardCohortCard'

const ErrorCard = (props: PropsWithChildren<{}>) => {
    return (
        <Box
            sx={{
                borderRadius: '2px', // Rounded corners
                boxShadow: 'none', // No shadow
                border: '1px solid #E0E0E0', // Border style
                backgroundColor: '#F3F4F6', // Background color when selected,
                color: '#E99689',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '15px'
            }}
        >
            {props.children}
        </Box>
    )
}

export interface DashboardCohortListProps {
    actions: Pick<typeof cohortListActions, 'cohortDuplicate'> &
        Pick<typeof cohortDeleteActions, 'cohortDelete'> &
        Pick<typeof cohortCommonActions, 'cohortSizeGet'>
    cohortList: CohortListItem[]
    cohortSizeLoading: CohortLoading
    datasetsList: CohortListItem[]
    searchValue: string | undefined
    handleSearchChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    selectedCohort?: CohortListItem
    selectedOwner?: DashboardCohortOwner
    onCreateCohortClick?: MouseEventHandler<HTMLButtonElement> | undefined
    reportType: ReportAnalyticsType
    cohortsLoading: boolean | undefined
    permissions: string[] | undefined
    setCohortId: Dispatch<SetStateAction<string | undefined>>
    setCohortOwner: Dispatch<SetStateAction<DashboardCohortOwner | undefined>>
    trackPage: (route: string) => ActionWithPayload<
        TrackingActionTypes.TRACK_PAGE,
        {
            location: {
                pathname: string
                search: string
                hash: string
                key: string
            }
            action: string
            isFirstRendering: boolean
        }
    >
    page: number
    setPage: Dispatch<SetStateAction<number>>
}

export const DashboardCohortList: FunctionComponent<DashboardCohortListProps> = (props) => {
    const {
        selectedCohort,
        cohortList,
        datasetsList,
        selectedOwner,
        searchValue,
        cohortSizeLoading,
        cohortsLoading,
        permissions,
        setCohortId,
        setCohortOwner,
        page,
        setPage
    } = props

    const theme = useTheme()

    // A ref to scrollable div where the cohorts live
    const cohortListRef = useRef<HTMLDivElement | null>(null)
    // A ref to the currently selected cohort in the list
    const selectedCohortRef = useRef<HTMLDivElement | null>(null)
    // The last cohort id we scrolled to, stored to prevent multiple scrolls
    const lastScrolledCohortId = useRef<string | undefined>(undefined)
    // The auto scroll element that triggers loading the next page of cohorts
    const autoScrollRef = useRef<HTMLDivElement | null>(null)

    const [deleteModalCohort, setDeleteModalCohort] = useState<CohortListItem>()

    const cohorts = useMemo(
        () => (selectedOwner === DashboardCohortOwner.DATASET ? datasetsList : cohortList),
        [selectedOwner, datasetsList, cohortList]
    )

    const filteredCohorts = useMemo(() => {
        if (searchValue) {
            return cohorts.filter(
                (cohort) =>
                    cohort.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                    cohort.createdByEmail?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    cohort.analyticsDataset?.name.toLowerCase().includes(searchValue.toLowerCase())
            )
        }
        return cohorts
    }, [searchValue, cohorts])

    const visibleCohorts = useMemo(() => {
        if (searchValue) {
            return filteredCohorts
        }
        return cohorts
    }, [searchValue, filteredCohorts, cohorts])

    // Scroll to the currently selected cohort, if it isn't in the current view of the cohort list
    useEffect(() => {
        if (!selectedCohort || visibleCohorts.findIndex((x) => x.id === selectedCohort.id) === -1) {
            return
        }
        if (selectedCohort.id === lastScrolledCohortId.current) {
            return
        }
        if (selectedCohortRef.current && cohortListRef.current) {
            lastScrolledCohortId.current = selectedCohort.id
            const { top: cardTop, bottom: cardBottom } = selectedCohortRef.current.getBoundingClientRect()
            const { top: listTop, bottom: listBottom } = cohortListRef.current.getBoundingClientRect()
            const listScrollTop = cohortListRef.current.scrollTop
            const listScrollBottom = listScrollTop + listBottom - listTop
            const cardScrollTop = cardTop - listTop + listScrollTop
            const cardScrollBottom = cardScrollTop + cardBottom - cardTop

            if (cardScrollTop < listScrollTop || cardScrollBottom > listScrollBottom) {
                cohortListRef.current.scroll({
                    behavior: 'smooth',
                    top: cardScrollTop
                })
            }
        }
    }, [selectedCohort, visibleCohorts])

    const SearchField: (props: TextFieldProps) => JSX.Element = (props: TextFieldProps) => {
        const inputRef = useRef<HTMLInputElement>(null)

        useEffect(() => {
            if (props.value) {
                inputRef.current?.focus({ preventScroll: true })
            }
        }, [props.value])

        return (
            <TextField
                {...props}
                inputRef={inputRef}
                type='search'
                placeholder={t`Search`}
                sx={{ backgroundColor: '#FFFFFF', border: 'none', width: '100%', marginBottom: '10px' }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <SearchIcon sx={{ color: '#79A6D6', width: '40px', height: '40px' }} />
                        </InputAdornment>
                    )
                }}
            />
        )
    }

    useEffect(() => {
        if (autoScrollRef.current) {
            const observer = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    setPage(page + 1)
                }
            })
            observer.observe(autoScrollRef.current)
        }
    }, [page, setPage, autoScrollRef.current])

    const getButtonStyles = useCallback(
        (buttonType: DashboardCohortOwner): SxProps => {
            let styles: SxProps = { flex: 1, backgroundColor: 'white', color: '#000000', borderRadius: 0, fontWeight: 'bold' }
            if (props.selectedOwner === buttonType) {
                styles = { ...styles, backgroundColor: theme.palette.secondary.main, color: theme.palette.primary.main }
            }
            return styles
        },
        [props.selectedOwner, theme]
    )

    const handleCohortDuplicate = useCallback(
        (cohortId: string) => {
            props.actions.cohortDuplicate({ cohortId })
        },
        [props.actions]
    )
    const handleDeleteView = useCallback(
        (id: string) => setDeleteModalCohort(props.cohortList.find((cohort) => cohort.id === id)),
        [props.cohortList]
    )
    const handleDeleteHide = useCallback(() => setDeleteModalCohort(undefined), [])
    const handleRefreshCount = useCallback(
        (id: string, name: string) => {
            props.actions.cohortSizeGet({ cohortId: id, cohortName: name })
        },
        [props.actions]
    )

    const useIsMediumScreen = () => {
        const [windowWidth, setWindowWidth] = useState(window.innerWidth)
        const theme = useTheme()

        useEffect(() => {
            const handleResize = () => {
                setWindowWidth(window.innerWidth)
            }

            window.addEventListener('resize', handleResize)

            // Clean up the event listener when the component unmounts
            return () => {
                window.removeEventListener('resize', handleResize)
            }
        }, [])

        return useMemo(() => windowWidth >= theme.breakpoints.values.md, [theme.breakpoints.values.md, windowWidth])
    }

    const greaterThanMediumScreen = useIsMediumScreen()

    return (
        <Box minHeight={greaterThanMediumScreen ? `${window.innerHeight}px` : `${window.innerHeight * 0.35}px`}>
            <Box display='flex' flexDirection='column' height='100%'>
                <Box px={2} pt={3} pb={1}>
                    <Box display='flex' marginBottom='8' height={'65px'}>
                        <Button
                            sx={getButtonStyles(DashboardCohortOwner.DATASET)}
                            onClick={() => {
                                setCohortId(undefined)
                                setCohortOwner(DashboardCohortOwner.DATASET)
                            }}
                        >
                            <Trans>Datasets</Trans>
                        </Button>
                        <Button
                            sx={getButtonStyles(DashboardCohortOwner.CUSTOM)}
                            onClick={() => {
                                setCohortId(undefined)
                                setCohortOwner(DashboardCohortOwner.CUSTOM)
                            }}
                        >
                            <Trans>Custom Cohorts</Trans>
                        </Button>
                    </Box>
                    <Box>
                        <DebounceInput
                            element={SearchField}
                            minLength={2}
                            width='100%'
                            debounceTimeout={500}
                            value={searchValue || ''}
                            onChange={props.handleSearchChange}
                        />
                    </Box>
                </Box>
                <Box ref={cohortListRef} display='flex' flexDirection='column' gap={1} overflow='auto' px={2} pb={3}>
                    {visibleCohorts.map((cohort: CohortListItem) => {
                        const isSelected = cohort.id === selectedCohort?.id
                        return (
                            <Box
                                key={cohort.id}
                                ref={(ref: HTMLDivElement) => {
                                    if (isSelected) {
                                        selectedCohortRef.current = ref
                                    }
                                }}
                            >
                                <DashboardCohortCard
                                    cohort={cohort}
                                    isSelected={isSelected}
                                    onDeleteView={handleDeleteView}
                                    onDuplicate={handleCohortDuplicate}
                                    reportType={props.reportType}
                                    onRefreshSize={handleRefreshCount}
                                    sizeLoading={cohortSizeLoading[cohort.id]}
                                    permissions={permissions}
                                    setCohortId={setCohortId}
                                    trackPage={props.trackPage}
                                />
                            </Box>
                        )
                    })}
                    {searchValue && visibleCohorts.length === 0 && (
                        <ErrorCard>
                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} alignContent={'center'} justifyContent={'center'}>
                                <SearchOffIcon sx={{ width: '50px', height: '50px' }} />
                                <Typography sx={{ margin: '20px', fontWeight: '800' }}>
                                    <Trans>No results found.</Trans>
                                </Typography>
                            </Box>
                        </ErrorCard>
                    )}
                    {!cohortsLoading &&
                        !searchValue &&
                        visibleCohorts.length === 0 &&
                        (props.selectedOwner === DashboardCohortOwner.DATASET ? (
                            <ErrorCard>
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    <Typography sx={{ margin: '20px', fontWeight: '800' }}>
                                        <Trans>No datasets available.</Trans>
                                    </Typography>
                                </Box>
                            </ErrorCard>
                        ) : (
                            <ErrorCard>
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} alignContent={'center'} justifyContent={'center'}>
                                    <Button onClick={props.onCreateCohortClick} disabled={!props.onCreateCohortClick}>
                                        <AddCircleOutlineIcon sx={{ width: '50px', height: '50px', marginLeft: '10px' }} />
                                        <Typography sx={{ margin: '20px', fontWeight: '400' }}>
                                            <Trans>Create your first cohort</Trans>
                                        </Typography>
                                    </Button>
                                </Box>
                            </ErrorCard>
                        ))}
                    {cohortsLoading && !searchValue && (
                        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} alignContent={'center'} justifyContent={'center'}>
                            <CircularProgress sx={{ color: '#012D72' }} />
                        </Box>
                    )}
                    {!cohortsLoading && props.selectedOwner === DashboardCohortOwner.CUSTOM && (
                        <Box ref={autoScrollRef} height={'10px'} width={'auto'} />
                    )}
                </Box>
            </Box>
            {deleteModalCohort && <DeleteCohortDialog id={deleteModalCohort.id} actions={props.actions} onCancel={handleDeleteHide} />}
        </Box>
    )
}
